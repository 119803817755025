<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Detalles categoría
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editCategory">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <BaseInputText
                v-model="name"
                label="Nombre"
                placeholder="Nombre"
                rules="required"
                localize
              />
              <b-form-group
                  :label="'Categoria padre'"
                  label-for="category-parent"
              >
              <v-select
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectCategories"
                  v-model="parent"
                  :placeholder="'Categoria padre'"
              />
              </b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="'Orden'"
                  rules="required"
              >
                <b-form-group
                    :label="'Orden'"
                    label-for="category-order"
                >
                  <b-form-input
                      v-model="category.order"
                      name="order"
                      type="number"
                      min="0"
                      step="1"
                      :placeholder="'Orden'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Imagen'"
                label-for="product-image"
              >
                <ImageDropzone
                  v-if="categoryPrepare"
                  ref="image"
                  max-files="1"
                  :files="files"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                :label="'Descripción'"
                label-for="category-description"
              >
                <quill-editor
                  v-model="category.description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import FormService from '@/shared/service/form-service'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import BaseInputText from '@/components/ui/input/BaseInputText.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BaseInputText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageDropzone,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      name: {},
      categoryPrepare: false,
      parent: {},
      files: [],
    }
  },
  computed: {
    ...mapGetters({
      category: 'categories/getCategory',
      selectCategories: 'categories/getSelectCategoriesParent',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'categories/edit',
      getSelectCategories: 'categories/getSelectCategories',
      getCategory: 'categories/getCategory',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    handleSubmit() {
      this.$refs.editCategory.validate().then(success => {
        if (success) {
          const { id } = this.category
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, category: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = this.$refs.image.getFormData('myFile')

      if (this.parent) {
        data.append('parent_id', this.parent.id)
      } else {
        data.append('parent_id', '')
      }

      data.append('description', this.category.description)

      FormService.appendEntry(data, this.name, 'name')
      data.append('order', this.category.order)

      return data
    },
    setData() {
      if (this.category.image !== undefined && this.category.image !== null && this.category.image) {
        const file = {
          name: 'Image',
          url: this.category.image,
          urlDelete: `/productcategories/delete_image/${this.category.id}`,
        }
        this.files = [file]
      }
      if (this.category.parent) {
        this.parent = { id: this.category.parent.id, name: this.category.parent.name[this.currentLanguage] }
      }
      this.categoryPrepare = true
    },
  },
  async created() {
    await this.getCategory(this.$route.params.id)
    this.name = this.category.translations.name
    await this.getSelectCategories({ parent: 0 })
    await this.setData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
